const Discord = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M19.2283 6.13937C17.4976 4.7467 14.7599 4.51063 14.6427 4.50214C14.4609 4.48685 14.2877 4.58876 14.213 4.75689C14.2062 4.76708 14.1467 4.90465 14.0805 5.11865C15.2252 5.31226 16.6315 5.70119 17.9035 6.49093C18.1073 6.61661 18.1702 6.88495 18.0445 7.08876C17.9613 7.22293 17.8203 7.29596 17.6743 7.29596C17.5961 7.29596 17.5163 7.27388 17.445 7.22972C15.2575 5.87272 12.5265 5.80479 12 5.80479C11.4735 5.80479 8.74083 5.87272 6.55503 7.22972C6.35122 7.3571 6.08288 7.29426 5.9572 7.09045C5.82982 6.88495 5.89266 6.61831 6.09647 6.49093C7.36855 5.70289 8.7748 5.31226 9.9195 5.12034C9.85326 4.90465 9.79382 4.76878 9.78872 4.75689C9.7123 4.58876 9.54076 4.48346 9.35734 4.50214C9.24015 4.51063 6.50238 4.7467 4.74796 6.15805C3.83254 7.00554 2 11.958 2 16.2396C2 16.316 2.02038 16.389 2.05774 16.4553C3.32133 18.6767 6.77072 19.2576 7.55707 19.283C7.56046 19.283 7.56556 19.283 7.57065 19.283C7.70992 19.283 7.84069 19.2168 7.92221 19.1047L8.71705 18.011C6.57201 17.4573 5.47656 16.5164 5.41372 16.4604C5.2337 16.3024 5.21671 16.0273 5.37636 15.8472C5.53431 15.6672 5.80944 15.6502 5.98947 15.8082C6.01495 15.832 8.03261 17.5439 12 17.5439C15.9742 17.5439 17.9918 15.8252 18.0122 15.8082C18.1923 15.6519 18.4657 15.6672 18.6253 15.8489C18.7833 16.029 18.7663 16.3024 18.5863 16.4604C18.5234 16.5164 17.428 17.4573 15.2829 18.011L16.0778 19.1047C16.1593 19.2168 16.2901 19.283 16.4293 19.283C16.4344 19.283 16.4395 19.283 16.4429 19.283C17.2293 19.2576 20.6787 18.6767 21.9423 16.4553C21.9796 16.389 22 16.316 22 16.2396C22 11.958 20.1675 7.00554 19.2283 6.13937ZM9.17391 14.5004C8.33322 14.5004 7.65217 13.7226 7.65217 12.7613C7.65217 11.8 8.33322 11.0222 9.17391 11.0222C10.0146 11.0222 10.6957 11.8 10.6957 12.7613C10.6957 13.7226 10.0146 14.5004 9.17391 14.5004ZM14.8261 14.5004C13.9854 14.5004 13.3043 13.7226 13.3043 12.7613C13.3043 11.8 13.9854 11.0222 14.8261 11.0222C15.6668 11.0222 16.3478 11.8 16.3478 12.7613C16.3478 13.7226 15.6668 14.5004 14.8261 14.5004Z' />
    </svg>
  )
}

export default Discord

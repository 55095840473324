const Telegram = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1.37093 11.7023L6.20978 13.4949L8.0827 19.4738C8.20257 19.8566 8.6743 19.9981 8.9873 19.744L11.6846 17.5615C11.9673 17.3327 12.3701 17.3215 12.6654 17.5343L17.5304 21.0402C17.8653 21.2818 18.3399 21.0997 18.4238 20.698L21.9876 3.68224C22.0793 3.24338 21.645 2.8773 21.224 3.03896L1.36533 10.6434C0.875201 10.8308 0.879401 11.5196 1.37093 11.7023ZM7.78083 12.5408L17.2377 6.75934C17.4076 6.65578 17.5825 6.88388 17.4365 7.01828L9.63189 14.2194C9.35749 14.4728 9.18056 14.8121 9.13043 15.1804L8.8647 17.136C8.8293 17.3971 8.45997 17.4229 8.38723 17.1702L7.36477 13.6041C7.24777 13.1974 7.41837 12.7628 7.78083 12.5408Z' />
    </svg>
  )
}

export default Telegram

const Medium = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7.20477 6C10.6316 6 13.4093 8.69752 13.4093 12.0248C13.4093 15.3522 10.6314 18.0495 7.20477 18.0495C3.77817 18.0495 1 15.3522 1 12.0248C1 8.69752 3.77796 6 7.20477 6ZM17.1133 6.35292C18.8267 6.35292 20.2157 8.89207 20.2157 12.0248H20.2159C20.2159 15.1568 18.827 17.6968 17.1135 17.6968C15.4001 17.6968 14.0112 15.1568 14.0112 12.0248C14.0112 8.89288 15.3999 6.35292 17.1133 6.35292ZM21.9089 6.94369C22.5114 6.94369 23 9.21856 23 12.0248C23 14.8303 22.5116 17.106 21.9089 17.106C21.3062 17.106 20.818 14.8309 20.818 12.0248C20.818 9.21876 21.3064 6.94369 21.9089 6.94369Z' />
    </svg>
  )
}

export default Medium
